.root {
  width: 100%;
  height: 100%;

  border: 0;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    box-shadow: none;
    background-color: transparent;
  }
}

.clickedWithMouse {
  &:focus {
    outline: none;
  }
}

/* Default isOpen class - no styles yet */
.isOpen {
}
