.root {
  /* Layout */
  /* width: 100%; */

  display: block;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
/* Aspect ratio for is given inline */
.aspectPadding {
}

.aspectBox {
  /* Layout - image will take space defined by aspect ratio wrapper */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  position: absolute;
}
