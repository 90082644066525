.root {
  width: 100%;
  height: 100%;
  position: relative;
}

.track {
  top: 50%;
  width: 104%;
  height: 8px;
  margin-top: -4px;
  margin-left: -10px;
  position: absolute;
  /* padding: 0 10px; */

  border-radius: 4px;
  background-color: var(--colorDarkBlue);

  /* box-sizing: content-box; */
  /* background-color: var(--colorGrey100); */
  /* box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1); */

  @media (max-width: 767px) {
    width: 101%;
  }
}

.trackDisabled {
  background-color: var(--colorLightGray);
}

.range {
  top: 50%;
  height: 8px;
  margin-top: -4px;
  position: absolute;
  border-radius: 4px;
  background-color: var(--colorLightGray);
  /* background-color: var(--marketplaceColor); */
}
