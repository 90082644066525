.root {
  height: 24px;
  margin: 0 10px;
  position: relative;
  width: calc(100% - 20px);
}

.activeHandle {
  z-index: 1;
}
