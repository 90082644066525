.root {
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
}

.topbar {
  top: 0;
  position: sticky;
  z-index: var(--zIndexTopbar);
}

.main {
  display: grid;
}
