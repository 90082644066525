@import '../../styles/customMediaQueries.css';

.root {
  display: inline-block;

  @media (--vwMaxM) {
    width: 100%;
    /* background-color: blue; */
  }
}
