.root {
}

.input {
  border: 1px solid #9d9d9d;
}
.textarea {
}

.inputSuccess {
}
.inputError {
  border: var(--borderErrorField);
}
