@import '../../styles/customMediaQueries.css';

.root {
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;

  cursor: pointer;
  border-radius: 10px;
  transition: all 0.2s ease;
  border: 2px solid #e6e6e6;
  background-color: var(--colorWhite);

  /* Remove link's hover effect */
  &:hover {
    border-color: #20639b;
    text-decoration: none !important;
  }

  @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.aspectRatioWrapper {
  cursor: pointer;
  position: relative;
  border-radius: 10px;
  background: var(--colorGrey100);
  /* transition: var(--transitionStyleButton); */

  /* @media (--viewportMedium) {
    &:hover {
      transform: scale(1.02);
      box-shadow: var(--boxShadowListingCard);
    }
  } */
}

.favouritesSpan {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgb(0 0 0 / 25%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: 2px solid transparent;
  cursor: pointer;

  & svg {
    width: 100%;
    height: 100%;

    & > path:first-child {
      fill: #ed553b;
    }
  }
}

.favourited {
  border-color: #ed553b;

  & svg {
    & > path:last-child {
      fill: #ed553b;
    }
  }
}

.favouriteLoading {
  background-color: #ed553b40;
}

.rootForImage {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  /* border: solid 1px var(--colorGrey100); */
}

.info {
  /* Layout */
  height: 100%;
  padding: 14px 0 2px 0;
  display: flex;
  flex-direction: column;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 15px;
  color: #23232c;
  font-size: 14px;
  font-weight: 500;

  & span {
    font-size: 18px;
    font-weight: 700;
  }
}

.priceValue {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  margin-left: 2px;
  display: inline-flex;
  align-items: center;
  color: var(--marketplaceColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  composes: textSmall from global;

  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #ed553b;
  margin: 0 0 15px;
  width: max-content;

  &:hover {
    text-decoration: underline;
  }
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--colorGrey700);
  font-weight: var(--fontWeightMedium);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;

  /* use break-word if available */
  hyphens: auto;
  word-break: break-word;
}

.locationP {
  gap: 4px;
  display: flex;
  align-items: center;

  font-size: 12px;
  font-weight: 500;
  margin: 10px 0px;
  color: #9d9d9d;
  line-height: 1rem;

  & svg {
    min-width: 20px;
    min-height: 20px;
    align-self: flex-start;
  }
}

.desc {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 1rem;
}

.viewNo {
  width: 100%;
  height: 50px;
  color: #20639b;
  cursor: not-allowed;
  margin: auto 0 10px;
  border: 0;
  border-radius: 12px;
  background-color: #e6e6e6;

  & img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
}

.getBstPrc {
  width: 100%;
  padding: 10px 0px;

  border: 0;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  border-radius: 12px;
  background-color: #20639b;
}
