.rootTouchBuffer {
  /* Position */
  top: 50%;
  margin-top: -22px;
  margin-left: -22px;
  position: absolute;

  /* Layout */
  border: 0;
  padding: 0;
  width: 44px;
  height: 44px;
}

.rootTouchBuffer:nth-of-type(even) {
  display: none;
}

.visibleHandle {
  /* Position */
  top: 12px;
  margin-left: 12px;
  position: absolute;

  /* Layout */
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 50%;
  background-color: var(--marketplaceColor);

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  }
}

.invisibleHandle {
  background-color: var(--colorTextLightGray);
}

.dragged {
  transform: scale(1.1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
}
