.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list li {
  margin: 0.75rem 0rem;
}

.info {
  padding: 1rem;
  border-radius: 4px;
  color: rgb(0, 49, 0);
  background-color: rgb(230, 246, 230);
}

.item {
  border-bottom: 1px solid #ccc;
}

.itemBtn {
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;

  border: none;
  cursor: pointer;
  font-size: 1rem;
  text-align: left;
  background-color: transparent;
  color: var(--marketplaceColor);
}

.itemBtn:hover {
  background-color: #f3f3f3;
}

.itemBtnExpanded {
  background-color: #e7e7e7;
}

.itemContent {
  transition: height 0.25s cubic-bezier(0, 0, 0, 1);
}

.itemPanel {
  /* padding: 6px; */
}

.chevron {
  margin-left: auto;
  transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
}

.itemBtnExpanded .chevron {
  transform: rotate(180deg);
}
