.root {
  position: relative;
  /* background-color: #ff7979; */

  & label {
    /* margin-bottom: 35px; */
  }
}

.phone {
  margin-top: 0px;
  position: relative;

  & label {
    position: absolute;
    left: 0.8rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: var(--wardrobeColorDarkGray);
    transition: all 0.1s linear;
    font-weight: 400 !important;
  }

  & input {
    width: 100%;
    font-size: var(--wardrobeSemiSmallFont);
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    border-color: var(--wardrobeColorDimGrey);
    height: 48px;
    padding-top: 18px;
  }

  & input:focus + label {
    transform: translateY(-100%) scale(0.75);
    left: 0rem;
  }
}

.nonEmptyPhone {
  & label {
    transform: translateY(-100%) scale(0.75) !important;
    left: 0rem !important;
  }

  & input {
    border-left-width: 0px;
    border-color: var(--wardrobeColorBlack);
  }

  & input:hover,
  & input:focus,
  & input:active {
    border-left-width: 0px;
  }
}

.inputDiv {
  min-width: 100%;
  padding: 2px;
  min-height: 48px;
  border-radius: 6px;

  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding: 8px 6px; */
  border-radius: 6px;
  border: 1px solid #9d9d9d;
  /* border-radius: var(--borderRadius10); */
  /* border: 1px solid var(--matterColorDimGray); */

  & > .divider {
    height: 25px;
    max-width: 1px;
    border: 1px solid #e6e6e6;
  }

  & input {
    outline: none;
    flex-grow: 1;
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
    padding: 6px 8px;
    border-width: 0px !important;
    color: var(--matterColorText);
    background-color: transparent;

    @media (max-width: 575px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  & input:focus {
    box-shadow: none;
  }

  & button {
    /* background-color: burlywood; */

    /* font-size: 18px; */
    gap: 10px;
    line-height: 20px;
    font-weight: 400;
    /* color: #292929 !important; */
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 12px;
    color: var(--matterColorText);
    /* margin-right: 1px; */
    border: 0px;
    border-right: 1px solid var(--matterColorTransDimGray);
    outline: none;
    cursor: pointer;

    /* & > span:first-child {
      margin-right: 12px;
      font-weight: 400;
    } */

    & > svg:last-child {
      width: 8px;
    }

    @media (max-width: 575px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.invalidInput {
  border: 1px solid red;
  /* border-color: var(--failColor); */
}

.phoneDialCodesContainer {
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d3d2d2;

  z-index: 9;
  padding: 4px;
  min-width: 300px;
  position: absolute;
  left: 0px;
  bottom: -12px;
  transform: translateY(100%);
  /* border-radius: var(--borderRadius10); */
  box-shadow: var(--boxShadowFieldSelect);

  @media (max-width: 575px) {
    min-width: 250px;
  }

  & input {
    padding: 10px 12px;
    font-family: var(--fontFamilyFiraSans);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 0px;
    background: #fff;
    border: 1px solid var(--matterColorMeadow);
    border-radius: var(--borderRadius10);
    width: calc(100% - 16px);
    margin: 8px 8px 4px;
    color: var(--matterColorText);
  }

  & .phoneDialCodes {
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow-y: auto;
    margin: 0;
    padding: 8px;

    /* border-top: 1px solid var(--matterColorMeadow); */
    & li {
      & button {
        width: 100%;
        border: 0px;
        outline: none !important;
        padding: 12px;
        font-size: 16px;
        line-height: 24px;
        background-color: var(--matterColorWhiteSmoke);
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: var(--matterColorText);
        font-family: var(--fontFamilyBarlow);
        cursor: pointer;
        font-weight: 400;
        border-radius: var(--borderRadius10);

        &:active,
        &:hover,
        &:focus {
          background-color: var(--matterColorBermuda);
          color: #000;
          font-weight: 400;
        }
      }
    }

    & li:last-child button {
      margin-bottom: 0rem;
    }

    & .selectedDialCode button {
      color: #fff;
      border-radius: 6px;
      background-color: #20639b;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      font-family: var(--fontFamilyBarlow);
      /* color: var(--matterColorText); */
      /* background-color: var(--matterColorMeadow); */
      transition: all 200ms ease;
    }

    & .selectedDialCode button:active,
    & .selectedDialCode button:hover,
    & .selectedDialCode button:focus {
      color: #fff;
      font-weight: 600;
      background-color: #173f5f;
    }

    & .noSearchOption button {
      cursor: not-allowed;
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  & .phoneDialCodes::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & .phoneDialCodes {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.phoneNumberContainer {
  display: flex;
  flex-direction: row;

  & > span:first-child {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--wardrobeColorBlack);
    cursor: pointer;
    font-family: var(--wardrobeInter);
    font-size: var(--wardrobeSemiSmallFont);
    border: 1px solid var(--wardrobeColorBlack);
    padding-left: 0.8rem;
    border-right-width: 0px;

    & > span:nth-child(2) {
      width: 8px;
      height: 8px;
      border: 4px solid var(--wardrobeColorWhite);
      border-top-color: var(--wardrobeColorBlack);
      margin: 4px 0.5rem 0rem;
    }

    & > span:last-child {
      width: 1px;
      height: 32px;
      background-color: var(--wardrobeColorDimGrey);
    }
  }
}

.disablePhoneDialCodesContainer {
  display: none !important;
}

.validationError {
  /* overflow: visible;
  white-space: nowrap; */
  /* background-color: orange; */
}
