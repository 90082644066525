@import '../../styles/customMediaQueries.css';

.root {
}

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;
  height: unset;

  @media (--vwM) {
    flex-basis: 576px;
    /* margin-top: 12.5vh; */
    /* margin-bottom: 12.5vh; */
    /* padding: var(--modalPaddingMedium); */
    /* background-color: var(--colorWhite); */
  }

  @media (--viewportLarge) {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
    background-color: transparent;
  }
}

.modalHeading {
  padding: 0 24px;
  margin-top: 89px;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.brandSection {
  margin-top: 1rem;

  gap: 10px;
  display: flex;
  align-items: center;
}

.brandImage {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  object-fit: cover;
  object-position: center;

  border-radius: 10px;
  border-style: dashed;
  border: 1px solid var(--colorGrey300);
}

.brandName {
  /* Layout */
  margin: 0;
  padding: 0;

  /* Font */
  color: var(--marketplaceColorDark);
}

.price {
  composes: h4 from global;
  margin: 0 0 0 24px;

  color: var(--marketplaceColor);
  font-weight: var(--fontWeightBold);

  @media (--viewportMedium) {
    margin: 0;
    padding: 0;
  }
}

.perUnit {
  /* Font */
  composes: h4 from global;
  font-weight: var(--fontWeightBold);

  color: var(--marketplaceColor);
  padding: 0;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}

.providerAvatar {
  /* display: none; */

  @media (--viewportLarge) {
    display: flex;
    align-self: flex-start;
  }
}

.providerNamePlain {
  display: inline;

  @media (--viewportLarge) {
    display: none;
  }
}

.providerNameLinked {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}

.orderHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 0;
  }
}

.orderTitle {
  /* Font */
  color: var(--colorGrey700);

  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.orderHelp {
  composes: h5 from global;
  display: none;

  @media (--viewportMedium) {
    margin-top: 0;
    display: block;
    color: var(--colorGrey700);
  }
}

.bookingForm {
  padding: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  left: 0;
  bottom: 0;
  z-index: 10;
  width: 100vw;
  display: flex;
  position: fixed;
  padding: 18px 24px 18px 16px;

  box-shadow: var(--boxShadowTop);
  background-color: var(--colorWhite);

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainerInCTA,
.priceContainer {
  /* Layout */
  padding: 3px 0;
  margin-right: 22px;

  gap: 4px;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: baseline;

  @media (--viewportMedium) {
    padding: 4px 0;
  }

  @media (--viewportLarge) {
    padding: 0;
    margin-right: unset;
    flex-direction: row;
  }
}

.priceContainerInCTA {
  gap: unset;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.priceValue {
  /* Font */
  composes: h3 from global;

  margin-top: 0;
  margin-bottom: 0px;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnitInCTA {
  /* Font */
  composes: marketplaceTinyFontStyles from global;

  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightSemiBold);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (--viewportLarge) {
  }
}

.closedListingButton {
  width: 100%;
  text-align: center;
  padding: 15px 24px 15px 24px;
  border-left: 1px solid var(--colorGrey100);
}

.receipt {
  flex-shrink: 0;
}

.error {
  composes: h4 from global;
  color: var(--colorFail);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.errorSidebar {
  color: var(--colorFail);
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.priceAndRating {
  gap: 10px;
  display: flex;
  justify-content: space-between;
}

.fullDesc {
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #20639b;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

/* Custom CSS */
.productName {
  flex-grow: 1;
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  color: var(--colorOrange);
  /* background-color: red; */

  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */

  @media (--vwMaxM) {
    font-size: 18px;
    line-height: 24px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;

  /* use break-word if available */
  hyphens: auto;
  word-break: break-word;
  /* background-color: red; */
}

.productRating {
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  white-space: nowrap;
  align-self: flex-start;
  /* background-color: blue; */

  & svg {
    margin: 0;
    padding: 0;
    fill: #3caea3;
  }

  & div:first-child {
    color: var(--colorBlue);
  }

  & div:last-child {
    gap: 6px;
    display: flex;
    align-items: center;

    color: #3caea3;
  }
}

.categoryDetails {
  margin-top: 1.5rem;

  display: flex;
  flex-direction: column;

  border-radius: 8px;
  border: 0.5px solid #d1d1d1;

  & div {
    display: flex;
    padding: 10px 16px;
    /* background-color: red; */

    & span:first-child {
      width: 45%;
      /* background-color: yellow; */
    }

    & span:last-child {
      width: 55%;
      /* background-color: blue; */
    }
  }

  & div:nth-child(even) {
    background-color: #eeeded;
  }
}

.listingServiceChipContainer {
  margin-top: 1.5rem;

  gap: 6px;
  display: flex;
  flex-wrap: wrap;

  & div {
    color: white;
    font-size: 12px;
    padding: 0px 10px;
    border-radius: 9999px;
    background-color: var(--colorGreen);
  }
}

.additionalDocumentsContainer {
  margin-top: 1.5rem;

  gap: 8px;
  display: flex;
  flex-direction: column;
}

.documentsHeading {
  margin: 0;
  padding: 0;
}

.additionalDocuments {
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.additionalDocument {
  padding: 8px 1rem;
  border-radius: 10px;
  border: 1px solid #333;
}

.fileName {
  margin: 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    cursor: pointer;
    color: var(--marketplaceColor);
  }
}

.fileSize {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: var(--colorTextLightGray);
}

.inquiry {
  & div {
    margin-top: 3rem;
    /* padding: 10px; */
    /* background-color: #3caea3; */

    @media (max-width: 1023px) {
      display: none;
    }
  }
}

.authorSection {
  margin-top: 3rem;

  gap: 10px;
  display: flex;
  flex-direction: column;
  /* background-color: olivedrab; */

  & h4 {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 600;
  }
}

.author {
  composes: p from global;
  gap: 10px;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  /* background-color: red; */

  @media (--vwS) {
    flex-direction: row;
  }
}

.authorAvatar {
  display: flex;
  align-self: flex-start;
}

.authorInformation {
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.authorCompany {
  width: max-content;

  gap: 6px;
  display: flex;
  align-items: center;

  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: var(--colorBlue);

  & span:nth-child(1) {
    text-decoration: underline;
    text-underline-offset: 5px;
  }
}

.authorName {
  margin-top: -4px;

  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: var(--colorOrange);
}

.customerAsAuthorName {
  width: max-content;

  gap: 6px;
  display: flex;
  align-items: center;

  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: var(--colorBlue);
}

.authorIsVerified {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  color: var(--colorTextLightGray);
}

.serviceChipContainer {
  gap: 6px;
  display: flex;
  flex-wrap: wrap;

  & div {
    color: white;
    font-size: 12px;
    padding: 0px 10px;
    border-radius: 9999px;
    background-color: var(--colorOrange);
  }
}

.authorEmail {
  gap: 10px;
  display: flex;
  align-items: center;
  word-break: break-all;

  & svg {
    fill: transparent;
  }
}

.authorCall {
  gap: 8px;
  display: flex;
  align-items: center;

  & svg {
    width: 21px;
    height: 21px;
    fill: transparent;

    & > path {
      fill: var(--marketplaceColorDark);
    }
  }
}

.authorWhatsApp {
  gap: 6px;
  display: flex;
  align-items: center;

  & svg {
    fill: transparent;
  }
}

.anchorTag {
  text-decoration: underline;
  text-underline-offset: 5px;
}
/* Custom CSS */
